import React from "react"
import { Flipper } from "react-flip-toolkit"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { theme } from "@src/theme"

const Style = createGlobalStyle`
* {
  box-sizing: border-box;
}

/* html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  overflow: hidden;
  background: hsl(59, 100%, 65%);
} */

`

export default ({ element, props }) => (
  <>
    <Style />
    <ThemeProvider theme={theme}>
      <Flipper flipKey={props.location.href}>{element}</Flipper>
    </ThemeProvider>
  </>
)
